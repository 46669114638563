import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import strings from './strings'

const Header = ({ language, path = '/' }) => (
  <div>
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <h1 style={{ margin: 0, marginRight: 15 }}>
        <Link
          to={`/${language}`}
          style={{
            color: `#7a7a7a`,
            textDecoration: `none`,
          }}
        >
          {strings[language].siteTitle}
        </Link>
      </h1>
      <div>
        <Link to={`/nl${path}`}>NL</Link>&nbsp;&nbsp;
        <Link to={`/fr${path}`}>FR</Link>
      </div>
    </div>
  </div>
)

Header.propTypes = {
  language: PropTypes.string,
}

Header.defaultProps = {
  language: `nl`,
}

export default Header
