import React from 'react'

import Layout from '../components/layout'

const VersionPage = () => (
  <Layout>
    <p>3a75bf25</p>
    <p>Tue Aug 10 10:13:55 UTC 2021</p>
  </Layout>
)

export default VersionPage
