const strings = {
  fr: {
    contactUs: 'Contactez nous',
    privacy: 'Politique de confidentialité',
    home: 'Accueil',
    siteTitle: 'Tests de théorie du conducteur',
  },
  nl: {
    contactUs: 'Neem contact met ons op',
    privacy: 'Privacybeleid',
    home: 'Huis',
    siteTitle: 'Driver Theorie Tests',
  },
}

export default strings
