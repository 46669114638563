import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import Header from './header'
import './layout.css'
import strings from '../components/strings'
import List from './list'

const Layout = ({ children, language = 'nl', path }) => (
  <StaticQuery
    query={graphql`
      query LayoutSiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header language={language} path={path} />
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
          }}
        >
          {children}
          <footer>
            <div
              style={{
                borderTop: 'solid 1px #cdcdcd',
                paddingTop: '2em',
                marginTop: '5em',
                textAlign: 'center',
              }}
            >
              <List>
                <Link to={`/${language}`}>{strings[language].home}</Link>
                <Link to={`/${language}/privacy`}>
                  {strings[language].privacy}{' '}
                </Link>
                <Link to={`/${language}/contact`}>
                  {strings[language].contactUs}
                </Link>
              </List>
            </div>
          </footer>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
