import React from 'react'
import listStyles from './list.module.css'

export default ({ children }) => (
  <ul className={listStyles.container}>
    {React.Children.map(children, (child, index) => (
      <li key={index}>{child}</li>
    ))}
  </ul>
)
